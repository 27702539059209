<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            --></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.product_id"
        class="ecommerce-card"
        style="cursor: pointer"
        no-body
        @click="
          $store.dispatch('app-ecommerce/SELECT_ITEM', product);
          $router.push({
            name: 'apps-e-commerce-product-details',
            params: { slug: product.slug },
          });
        "
      >
        <div class="item-img text-center">
          <b-link>
            <b-img
              :alt="`${product.name}-${product.product_id}`"
              fluid
              id="product-image"
              class="card-img-top"
              :src="product.image_url"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div>
              <h6 class="item-price">${{ product.price }}</h6>
            </div>
          </div>
          <h6 class="item-name">
            <div class="product-text">
              {{ product.name }}
            </div>
            <!-- <b-card-text class="item-name">
              <b-link
                class="ml-25"
                @click="$store.dispatch('app-ecommerce/SELECT_ITEM', product)"
                :to="{
                  name: 'apps-e-commerce-product-details',
                  params: { slug: product.slug },
                }"
              >
                {{ product.brand }}
              </b-link>
            </b-card-text> -->
          </h6>
          <b-card-text
            class="item-description mt-1"
            v-html="product.description"
          >
            {{ product.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">${{ product.price }} USD</h4>
            </div>
          </div>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :clearFilters="clearFilters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ShopLeftFilterSidebar from "./ECommerceShopLeftFilterSidebar.vue";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
import { useEcommerceUi, useEcommerce } from "../useEcommerce";
import { watch } from "@vue/composition-api";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
  },
  setup() {
    const { filters, clearFilters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const fetchShopProducts = async () => {
      await store.dispatch("app-ecommerce/FETCH_BUSINESS_PRODUCTS");
      const eCommerceData = store.state["app-ecommerce"].products;
      products.value = eCommerceData;
      totalProducts.value = eCommerceData.length;
    };

    const handleFilterAndSort = async () => {
      /**
       * ===============================================================
       *                        MAIN CATEGORIES
       * ===============================================================
       */
      let filteredCategories;
      let filteredBrands;
      if (filters.value.categories.length > 0) {
        filteredCategories = store.state["app-ecommerce"].products.filter(
          (e) =>
            e.categories.toLowerCase() == filters.value.categories.toLowerCase()
        );
      }
      /**
       * ===============================================================
       *                      OTHER CATEGORIES
       * ===============================================================
       */
      if (filters.value.brands.length > 0) {
        filteredBrands = store.state["app-ecommerce"].products.filter(
          (e) =>
            e.categories.toLowerCase() == filters.value.brands.toLowerCase()
        );
      }

      if (
        filters.value.categories.length > 0 &&
        filters.value.brands.length == 0
      ) {
        products.value = [...filteredCategories];
        totalProducts.value = products.value.length;
      } else if (
        filters.value.brands.length > 0 &&
        filters.value.categories.length == 0
      ) {
        products.value = [...filteredBrands];
        totalProducts.value = products.value.length;
      } else if (
        filters.value.categories.length > 0 &&
        filters.value.brands.length > 0
      ) {
        products.value = [...filteredCategories, ...filteredBrands];
        totalProducts.value = products.value.length;
      } else {
        products.value = store.state["app-ecommerce"].products;
        totalProducts.value = products.value.length;
      }

      /**
       * =======================================================
       *                SORT PRODTUCTS
       * =======================================================
       */
      let sortDesc = false;
      const sortByKey = (() => {
        if (sortBy.value.value === "price-desc") {
          sortDesc = true;
          return "price";
        }
        if (sortBy.value.value === "price-asc") {
          return "price";
        }
        sortDesc = true;
        return "id";
      })();

      if (products.value.length > 0) {
        const sortedData = products.value.sort(sortCompare(sortByKey));
        if (sortDesc) sortedData.reverse();
      }

      /**
       * =======================================================
       *                SORT PRODTUCTS
       * =======================================================
       */
      if (filters.value.q) {
        const queryLowered = filters.value.q.toLowerCase();
        const filteredData = products.value.filter((product) =>
          product.name.toLowerCase().includes(queryLowered)
        );
        products.value = [...filteredData];
        totalProducts.value = products.value.length;
      }
    };

    const sortCompare = (key) => (a, b) => {
      const fieldA = a[key];
      const fieldB = b[key];

      let comparison = 0;
      if (parseInt(fieldA) > parseInt(fieldB)) {
        comparison = 1;
      } else if (parseInt(fieldA) < parseInt(fieldB)) {
        comparison = -1;
      }
      return comparison;
    };

    if (store.state["app-ecommerce"].products.length == 0) {
      fetchShopProducts();
    } else {
      const eCommerceData = store.state["app-ecommerce"].products;
      products.value = eCommerceData;
      totalProducts.value = eCommerceData.length;
    }

    watch(
      [filters, sortBy],
      () => {
        handleFilterAndSort();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      clearFilters,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="css">
.product-text {
 color: gray;
 font-weight: 600;
}
</style>