<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">Filters</h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <div>
            <feather-icon icon="XIcon" />
            <b-button variant="flat" @click="clearFilters()"> All</b-button>
          </div>
          <!-- Categories -->
          <div class="product-categories">
            <h6 class="category-title">Categories</h6>
            
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
            />
          </div>

          <!-- Brands 
          <div class="brands">
            <h6 class="filter-title">Other Categories</h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            />
          </div>
          -->
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormRadioGroup,
  BLink,
  BButton,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BButton,

    // 3rd Party
    VueSlider,
  },
  props: {
    clearFilters: {
      type: Function,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.category-title {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
</style>
